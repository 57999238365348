import { useHead } from '#imports'
import { type Ref } from 'vue'

import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useAddCanonicalLink } from '~/scopes/seo/composables/useAddCanonicalLink'

import translations from '../Landing.translations'

import { useCMSAlternateLinks } from './useCMSAlternateLinks'
import { useCMSMeta } from './useCMSMeta'

export function buildHead(data: Ref<BasePageContent | null>) {
  if (!data.value)
    return {
      title: '',
      titleTemplate: '',
      meta: [],
      link: [],
    }

  const i18n = useI18n()
  const companyName = i18n(translations.companyName)
  const meta = useCMSMeta(data)
  const alternateLinks = useCMSAlternateLinks(data)

  return {
    title: data.value.titleSeo,
    titleTemplate: `%s | ${companyName}`,
    meta,
    link: [...alternateLinks],
  }
}

export function useCMSHead(data: Ref<BasePageContent | null>) {
  if (!data.value) return

  useHead(buildHead(data))
  useAddCanonicalLink()
}
