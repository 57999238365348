import { createError } from '#imports'
import { type Ref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { OWNER_BY_PAGE_TYPE } from '../service/content-service.config'

import { usePageParamsFromRoute } from './usePageParamsFromRoute'

export function useHandleFetchError(
  error: Ref<{
    statusCode: number
    message: string
  } | null>,
) {
  if (!error.value) {
    return
  }

  const logger = useLogger()

  const {
    pageType,
    pageName,
    pageCategory: category,
  } = usePageParamsFromRoute()

  const owner = OWNER_BY_PAGE_TYPE[pageType] || ''
  const pageCategory = category ? `[${category}]` : ''

  const message = `${owner}[${pageType}][${pageName}]${pageCategory} ${error.value.message}`
  logger.error(message)

  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}
