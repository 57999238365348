import { useRequestURL } from '#imports'

import type {
  BasePageContentAPI,
  BlockType,
  MappedApiPageContent,
  ParentPageInfos,
} from '@backmarket/http-api/src/api-specs-content'
import type { EmailCollectorApiProps } from '@backmarket/http-api/src/api-specs-content/models/email-collector'
import type {
  SectionBannerApiProps,
  SectionBannerBackgroundApiProps,
} from '@backmarket/http-api/src/api-specs-content/models/section-banner'
import type { BackgroundProps } from '@backmarket/nuxt-layer-cms/Background.types'
import type { EmailCollectorProps } from '@backmarket/nuxt-layer-cms/EmailCollector.types'
import type { SectionBannerProps } from '@backmarket/nuxt-layer-cms/SectionBanner.types'
import type { Maybe } from '@backmarket/utils/types/Maybe'

import { buildPropsWithCorrectLinks } from '../helpers/link/buildPropsWithCorrectLinks'

function mapToBackground(
  props: SectionBannerBackgroundApiProps,
): BackgroundProps {
  if (
    props.sectionBannerImageBackground &&
    'image' in props.sectionBannerImageBackground
  )
    return {
      background: {
        image: props.sectionBannerImageBackground.image,
        mode: props.sectionBannerImageBackground.mode,
      },
    }

  if (props.sectionBannerMonochromeBackground?.theme) {
    return {
      background: {
        theme: props.sectionBannerMonochromeBackground.theme,
      },
    }
  }

  return { background: undefined }
}

function mapEmailCollectorProps(
  apiProps: EmailCollectorApiProps,
): EmailCollectorProps {
  const {
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
    ...rest
  } = apiProps
  const { background } = mapToBackground({
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
  })

  return {
    ...rest,
    background,
  }
}

function mapSectionBannerProps(
  apiProps: SectionBannerApiProps,
): SectionBannerProps {
  const {
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
    ...rest
  } = apiProps
  const { background } = mapToBackground({
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
  })

  return {
    ...rest,
    background,
  }
}

// TODO: map BlockType to ApiProps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Mapper = (params: any) => any
const blockPropsMappers: Partial<Record<BlockType, Mapper>> = {
  EmailCollector: mapEmailCollectorProps,
  SectionBanner: mapSectionBannerProps,
}
export function useFormatContentResponse() {
  const currentUrl = useRequestURL()

  function formatContentResponse<P extends BasePageContentAPI>(
    response: P,
  ): MappedApiPageContent<P> {
    const pageName = response.name
    const pageType = response.type

    const apiParentPageInfos = response.parentPageInfos

    const parentPageInfos: Maybe<ParentPageInfos> = apiParentPageInfos
      ? {
          pageName: apiParentPageInfos.name,
          titleBreadcrumb: apiParentPageInfos.titleBreadcrumb,
        }
      : null

    return {
      ...response,
      parentPageInfos,
      pageName,
      pageType,
      blocks: response.blocks.map((block) => {
        const propsWithCorrectLinks = buildPropsWithCorrectLinks({
          props: block.props,
          currentUrl,
        })

        const blockMapper = blockPropsMappers[block.type]

        if (blockMapper) {
          const mappedProps = blockMapper(propsWithCorrectLinks)

          return {
            ...block,
            props: mappedProps,
          }
        }

        return {
          ...block,
          props: propsWithCorrectLinks,
        }
      }),
    }
  }

  return formatContentResponse
}
