import { flatMap } from '@backmarket/utils/collection/flatMap'
import type { ReadonlyDeep } from 'type-fest'

type Tags = ReadonlyDeep<string[]>

type TagsWithContent = {
  tags: Tags
  content: string
}

const buildMeta = ({ tag, content }: { tag: string; content: string }) => {
  return {
    hid: tag,
    name: tag,
    content,
  }
}

export const createMeta = ({
  tags = [],
  content,
}: {
  tags: Tags
  content: string
}) => {
  return tags.map((tag) => buildMeta({ tag, content })) || []
}

export const createMetaForGroupedTags = (args: TagsWithContent[] = []) => {
  return flatMap(args, ({ tags, content }) => {
    return createMeta({ tags, content })
  })
}

export const createSocialMediaTagsFor = (
  socialMedia: Record<string, string>,
) => {
  return Object.entries(socialMedia).map(([key, content]) => {
    const tag = `${key}:profile`

    return buildMeta({ tag, content })
  })
}
