import {
  getPageCategoryContent,
  getPageContent,
} from '@backmarket/http-api/src/api-specs-content/endpoints'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useFetchFormattedPageContent } from './useFetchFormattedPageContent'
import { usePageParamsFromRoute } from './usePageParamsFromRoute'

export async function useFetchContent() {
  const pathParams = usePageParamsFromRoute()

  const endpoint = isEmpty(pathParams.pageCategory)
    ? getPageContent
    : getPageCategoryContent

  return await useFetchFormattedPageContent(endpoint, {
    pathParams,
  })
}
