import { createHttpEndpoint } from '../utils'

import type { ArticleVotes } from './models/article-votes'
import type { BasePageContentAPI } from './models/base-page-content'
import type { HomeNavigationContent } from './models/home-navigation-page-content'
import type { PriceGuide } from './models/price-guide'

import type { ApiArticlePageContent } from '.'

/**
 * Retrieve content for a given pageName and pageType
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/content-service-web-api/definition}
 */
export const getPageContent = createHttpEndpoint<BasePageContentAPI>({
  method: 'GET',
  operationId: 'fetchContentFromContentService',
  path: '/content/page/:pageType/:pageName',
})

/**
 * Retrieve content for a given pageName, pageCategory and pageType
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/content-service-web-api/definition}
 */
export const getPageCategoryContent = createHttpEndpoint<BasePageContentAPI>({
  method: 'GET',
  operationId: 'fetchCategoryContentFromContentService',
  path: '/content/page/:pageType/:pageName/:pageCategory',
})

export const getHomeNavigationContent =
  createHttpEndpoint<HomeNavigationContent>({
    method: 'GET',
    operationId: 'fetchHomeNavigationContent',
    path: '/bm/navigation/home',
  })

/**
 * SEO Articles related
 */
export const getArticleVotes = createHttpEndpoint<ArticleVotes>({
  method: 'GET',
  operationId: 'fetchArticlesVotes',
  path: '/bm/branding/articles/:articleSlug/votes',
  transformResponseToCamelCase: true,
})

export const voteForArticle = createHttpEndpoint<ArticleVotes>({
  method: 'POST',
  operationId: 'voteForArticle',
  path: '/bm/branding/articles/:articleSlug/votes',
})

export const getArticleContent = createHttpEndpoint<ApiArticlePageContent>({
  method: 'GET',
  operationId: 'getArticleContent',
  path: '/content/page/:pageType/:pageName',
})

/**
 * Retrieve price guide data for a given product model slug
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/content-api/definition}
 */
export const getPriceGuide = createHttpEndpoint<PriceGuide>({
  method: 'GET',
  operationId: 'getPriceGuide',
  path: '/content/price-guide/:productModelSlug',
})
