import type { Ref } from 'vue'

import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import {
  DESCRIPTION_META_TAGS,
  IMAGE_META_TAGS,
  NOINDEX_META_TAG,
  TITLE_META_TAGS,
} from '../../../utils/seo/constants'
import translations from '../Landing.translations'
import { createMetaForGroupedTags } from '../helpers/meta/meta'

export function useCMSMeta(data: Ref<BasePageContent | null>) {
  if (!data.value) return []

  const { meta, hideFromSearchEngine, titleSeo } = data.value
  const { description, image } = meta
  const imageUrl = image.src
  const i18n = useI18n()
  const companyName = i18n(translations.companyName)

  return [
    ...createMetaForGroupedTags([
      {
        tags: DESCRIPTION_META_TAGS,
        content: description,
      },
      {
        tags: TITLE_META_TAGS,
        content: `${titleSeo} | ${companyName}`,
      },
      {
        tags: IMAGE_META_TAGS,
        content: imageUrl,
      },
    ]),
    ...(hideFromSearchEngine === true ? [NOINDEX_META_TAG] : []),
  ]
}
